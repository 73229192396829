import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { environment } from 'src/environments/environment';

// interface IItemRow {
//   id: number;
//   ItemNo: string;
//   Title: string;
//   Description: string;
//   Collection: string;
//   Creatorid: string;
//   Dated: string;
//   Image: string;
//   Place: string;
//   Origin: string;
//   EntryDate: string;
//   OldItemNo: string;
//   Exhibitions: string;
//   Bibliography: string;
//   HandlePermission: string;
//   Digitized: string;
//   FileName: string;
//   Position: string;
//   Preservation: string;
//   Comments: string;
//   Syntelestis: string;
//   Documenter: string;
// }

// interface IPhotographerRow {
//   id: number;
//   Name: string;
//   Surname: string;
//   CV: string;
//   Birthday: string;
//   Deathday: string;
//   Created: string;
//   Updated: string;
// }

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css'],
})
export class DataGridComponent implements OnInit {
  themeClass = 'ag-theme-alpine';
  rowData: any[] = []; // Update as per actual response structure
  showNewEntryForm = false;
  newEntry: any = {}; // Use 'any' type for newEntry to accommodate both IItemRow and IPhotographerRow
  dataType: 'Items' | 'Photographers' = 'Items'; // Default to Items

  itemColDefs: ColDef<any>[] = [
    { field: 'id' },
    { field: 'ItemNo', minWidth: 200 },
    { field: 'Title', minWidth: 500 },
    { field: 'Description', minWidth: 500 },
    { field: 'Collection' },
    { field: 'Creatorid' },
    { field: 'Dated' },
    { field: 'Image' },
    { field: 'Place' },
    { field: 'Origin' },
    { field: 'EntryDate' },
    { field: 'OldItemNo' },
    { field: 'Exhibitions' },
    { field: 'Bibliography' },
    { field: 'HandlePermission' },
    { field: 'Digitized' },
    { field: 'FileName' },
    { field: 'Position' },
    { field: 'Preservation' },
    { field: 'Comments' },
    { field: 'Syntelestis' },
    { field: 'Documenter' },
  ];

  photographerColDefs: ColDef<any>[] = [
    { field: 'id' },
    { field: 'name' },
    { field: 'surname' },
    { field: 'cv' },
    { field: 'birthday' },
    { field: 'deathday' },
    { field: 'created' },
    { field: 'updated' },
  ];

  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
    cellStyle: { 'min-width': '200px', 'white-space': 'normal' },
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData(); // Fetch initial data
  }

  fetchData(): void {
    const endpoint = this.dataType === 'Items' ? '/works' : '/artists'; // Adjust endpoint based on dataType
    this.http.get<any[]>(environment.apiUrl + endpoint).subscribe(
      (data) => {
        this.rowData = data; // Assuming data structure matches
        this.sizeToFit(); // Adjust grid columns
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  addNewEntry(): void {
    const endpoint = this.dataType === 'Items' ? '/works' : '/artists'; // Adjust endpoint based on dataType
    this.http.post<any>(environment.apiUrl + endpoint, this.newEntry).subscribe(
      (newData) => {
        this.rowData.push(newData); // Assuming newData is the newly added item
        // this.showNewEntryForm = false;
        this.newEntry = {}; // Clear newEntry after submission
        this.sizeToFit(); // Adjust grid columns
        alert('Created successfully!');
      },
      (error) => {
        console.error('Error adding new entry:', error);
      }
    );
  }

  sizeToFit(): void {
    setTimeout(() => {
      const gridApi = (document.querySelector('.ag-grid-angular') as any)?.__agGridAngular.gridOptions.api;
      gridApi?.sizeColumnsToFit();
    });
  }

  openNewEntryForm(): void {
	if (this.showNewEntryForm == false) {
		this.showNewEntryForm = true;
	}
	else {
		this.showNewEntryForm = false;
	}
  }

//   openNewEntryForm(): void {
//     this.showNewEntryForm = true;
//   }

  switchDataType(type: 'Items' | 'Photographers'): void {
    this.dataType = type;
    this.fetchData();
	console.log(this.dataType);
  }

  get columnDefs(): ColDef<any>[] {
    return this.dataType === 'Items' ? this.itemColDefs : this.photographerColDefs;
  }
}
