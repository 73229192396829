import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignupComponent } from '../signup/signup.component';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  artist: any = {
    email: '',
    password: ''
  };

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  // Define hardcoded credentials
  private hardcodedEmail = 'curator@test.gr';
  private hardcodedPassword = 'Momus52Vr!';

  constructor(
    private toastr: ToastrService,
    private router: Router,
    public modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void { }

  openModalForgot() {
    this.modalRef = this.modalService.show(ForgotpasswordComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalSignup() {
    this.modalRef = this.modalService.show(SignupComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      console.log(res)
      this.modalRef.hide();
    });
  }

  onLoginSubmit(userForm: NgForm) {
    const { email, password } = this.artist;

    if (email === this.hardcodedEmail && password === this.hardcodedPassword) {
      this.translate.get('You are now logged in').subscribe(res => {
        this.toastr.success(res);
      });

      // Simulate storing user data and navigating
      localStorage.setItem('user', JSON.stringify({ email }));

      // Navigate to a different route based on role
      // For simplicity, let's assume the hardcoded user is an artist
      this.router.navigate(['/data-grid']);

      this.triggerCloseModalEvent();
    } else {
      this.translate.get('Invalid email address or password').subscribe(res => {
        this.toastr.error(res);
      });
    }
  }

  /*
  * Trigger close event to the parent component
  */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
