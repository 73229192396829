import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  /*
  * Get user's profile information
  */
  getProfile(profile_id: string = undefined) {
    const token = this.authService.loadToken();

    let user_id: string;

    if (!profile_id) {
      user_id = this.authService.getUserIdFromToken();
    } else {
      user_id = profile_id
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/artists/' + user_id, { headers: headers }).pipe();
  }

  /*
  * Get user's profile information without token
  */
  getProfileForAnonymous(profile_id: string = undefined) {

    let user_id: string;

    if (!profile_id) {
      user_id = this.authService.getUserIdFromToken();
    } else {
      user_id = profile_id
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/artists/' + user_id, { headers: headers }).pipe();
  }

  /*
    * Get user's profile information
  */
  getProfileCurator(profile_id: string = undefined) {
    const token = this.authService.loadToken();

    let user_id: string;

    if (!profile_id) {
      user_id = this.authService.getUserIdFromToken();
    } else {
      user_id = profile_id
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/curators/' + user_id, { headers: headers }).pipe();
  }

  getArtists() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(environment.apiUrl + '/artists/', { headers: headers }).pipe();
  }

  /*
  * Get all work of arts information without token and status: APPROVED
  */
  getWork() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/works/', { headers: headers }).pipe();
  }

  /*
  * Get all work of arts with a specific tag (Search component) and status: APPROVED
  */
  getWorkTags(tagName: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/works/' + '?where={"status":"APPROVED", "tags":"' + tagName + '"}' + '&embedded={"owner_id":1}', { headers: headers }).pipe();
  }

  /*
  * Get ALL work of arts information  of an artist
  */
  getWorkOfArtist(owner_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/works/' + '?where={"owner_id":"' + owner_id + '"}' + '&embedded={"owner_id":1}', { headers: headers }).pipe();
  }

  //Get all works Of Other Artists where status= 'APPROVED'
  getWorkOfOtherArtist(owner_id: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/works/' + '?where={"status":"APPROVED", "owner_id":"' + owner_id + '"}' + '&embedded={"owner_id":1}', { headers: headers }).pipe();
  }

  /*
   * Get work of arts information  of all artists
     Used at profile-view component
   */
  getWorkOfAllArtists() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/works' + '?embedded={"owner_id":1}', { headers: headers }).pipe();
  }

  loadWorkPhoto(photoURL: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      }),
      responseType: 'arraybuffer' as 'json'
    };

    return this.http.get<any>(environment.apiUrlImg + photoURL, httpOptions).pipe();
  }


  postFavourites(favouriteBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.post<any>(environment.apiUrl + '/favourites', favouriteBody, { headers: headers }).pipe();
  }

  getFavouriteId(owner_id: string, work_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/favourites' + '?where={"$and":[{"owner_id": "' + owner_id + '"},{"work":"' + work_id + '"}]}', { headers: headers }).pipe();
  }

  deleteFavourite(favourite_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.delete<any>(environment.apiUrl + '/favourites/' + favourite_id, { headers: headers }).pipe();
  }

  deleteWork(work_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.delete<any>(environment.apiUrl + '/works/' + work_id, { headers: headers }).pipe();
  }

  deleteExhibition(exhibition_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.delete<any>(environment.apiUrl + '/exhibitions/' + exhibition_id, { headers: headers }).pipe();
  }

  deleteAccount(artist_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.delete<any>(environment.apiUrl + '/artists/' + artist_id, { headers: headers }).pipe();
  }

  getFavouritesOfArtist(owner_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/favourites/' + '?where={"owner_id":"' + owner_id + '"}' + '&embedded={"work":1, "owner_id":1, "work.owner_id":1}', { headers: headers }).pipe();
  }

  // Rating a work for anonymous users
  postRating(ratingBody: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(environment.apiUrl + '/ratings', ratingBody, { headers: headers }).pipe();
  }

  // Rating a work for connected users
  postRatingUsers(ratingBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.post<any>(environment.apiUrl + '/ratings', ratingBody, { headers: headers }).pipe();
  }

  postExhibition(exhibitionBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.post<any>(environment.apiUrl + '/exhibitions', exhibitionBody, { headers: headers }).pipe();
  }

  editWork(work_id: string, editBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.patch<any>(environment.apiUrl + '/works/' + work_id, editBody, { headers: headers }).pipe();
  }

  editExhibition(exhibition_id: string, exhibitionBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.patch<any>(environment.apiUrl + '/exhibitions/' + exhibition_id, exhibitionBody, { headers: headers }).pipe();
  }

  // Get all exhibitions of a specific artist
  getExhibitionOfArtist(owner_id: string) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?where={"owner_id":"' + owner_id + '"}' + '&embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  //Get all exhibitions Of Other Artists where status= 'APPROVED'
  getExhibitionOfOtherArtists(owner_id: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?where={"status":"APPROVED", "owner_id":"' + owner_id + '"}' + '&embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  getExhibitionOfallfArtists() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?where={"status":"APPROVED"}' + '?embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  /*
    * Get all exhibition information-used at profile-curator component
  */
  getExhibitionOfallfArtistsCurator() {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  /*
  * Get all exhibition information  without token
  */
  getExhibition() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?where={"status":"APPROVED"}' + '&embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  statusExhibition(exhibition_id: string, statusBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.patch<any>(environment.apiUrl + '/exhibitions/' + exhibition_id, statusBody, { headers: headers }).pipe();
  }

  statusWork(work_id: string, statusBody: any) {
    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.patch<any>(environment.apiUrl + '/works/' + work_id, statusBody, { headers: headers }).pipe();
  }

  getSpecificExhibition(exhibition_id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/exhibitions/' + '?where={"_id":"' + exhibition_id + '"}' + '&embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  getSpecificWork(work_id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/works/' + '?where={"_id":"' + work_id + '"}' + '&embedded={"works":1, "owner_id":1}', { headers: headers }).pipe();
  }

  getSpecificArtist(artist_id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(environment.apiUrl + '/artists/' + '?where={"_id":"' + artist_id + '"}', { headers: headers }).pipe();
  }
}