import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { DetailsComponent } from './details/details.component';
import { WorksofartComponent } from './worksofart/worksofart.component';
import { EExhibitionsComponent } from './e-exhibitions/e-exhibitions.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthService } from './services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { VrpageComponent } from './vrpage/vrpage.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileCuratorComponent } from './profile-curator/profile-curator.component';
import { AddanartworkComponent } from './addanartwork/addanartwork.component';
import { CreateExhibitionComponent } from './create-exhibition/create-exhibition.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from './helpers/auth.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WorksofartCardComponent } from './worksofart-card/worksofart-card.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { CreateExhibition2Component } from './create-exhibition2/create-exhibition2.component';
import { CreateExhibition3Component } from './create-exhibition3/create-exhibition3.component';
import { CreateExhibition4Component } from './create-exhibition4/create-exhibition4.component';
import { CreateExhibition5Component } from './create-exhibition5/create-exhibition5.component';
import { EExhibitionsCardComponent } from './e-exhibitions-card/e-exhibitions-card.component';
import { ParentCreateExhibitionComponent } from './parent-create-exhibition/parent-create-exhibition.component';
import { PreviewWorkofartComponent } from './preview-workofart/preview-workofart.component';
import { TranslateCompiler, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import { ProfileViewComponent } from './profile/profile-view/profile-view.component';
import { PreviewExhibitionComponent } from './preview-exhibition/preview-exhibition.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EditExhibitionComponent } from './edit-exhibition/edit-exhibition.component';
import { SetExhibitionComponent } from './set-exhibition/set-exhibition.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EExhibitionsVrComponent } from './e-exhibitions-vr/e-exhibitions-vr.component';
import { ConfirmMessageComponent } from './confirm-message/confirm-message.component';
import { ConfirmExhibitionComponent } from './confirm-exhibition/confirm-exhibition.component';
import { CreateExhibition6Component } from './create-exhibition6/create-exhibition6.component';
import { PreviewFavouritesProfileComponent } from './preview-favourites-profile/preview-favourites-profile.component';
import { DeleteWorkComponent } from './delete-work/delete-work.component';
import { DeleteExhibitionComponent } from './delete-exhibition/delete-exhibition.component';
import { PreviewCuratorComponent } from './preview-curator/preview-curator.component';
import { EditWorkCuratorComponent } from './edit-work-curator/edit-work-curator.component';
import { DeleteProfileUserComponent } from './delete-profile-user/delete-profile-user.component';
import { DeleteProfileCuratorComponent } from './delete-profile-curator/delete-profile-curator.component';
import { PreviewFullImageComponent } from './preview-full-image/preview-full-image.component';
import { EditExhibitionCuratorComponent } from './edit-exhibition-curator/edit-exhibition-curator.component';
import { ForgotPassword2Component } from './forgot-password2/forgot-password2.component';
import { EExhibitionsVr2Component } from './e-exhibitions-vr2/e-exhibitions-vr2.component';
import { EExhibitionsVrCustomComponent } from './e-exhibitions-vr-custom/e-exhibitions-vr-custom.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { AgGridModule } from 'ag-grid-angular';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    DetailsComponent,
    WorksofartComponent,
    EExhibitionsComponent,
    LoginComponent,
    SignupComponent,
    VrpageComponent,
    FooterComponent,
    SearchComponent,
    ForgotpasswordComponent,
    ProfileComponent,
    ProfileCuratorComponent,
    AddanartworkComponent,
    CreateExhibitionComponent,
    WorksofartCardComponent,
    TermsOfUseComponent,
    CreateExhibition2Component,
    CreateExhibition3Component,
    CreateExhibition4Component,
    CreateExhibition5Component,
    EExhibitionsCardComponent,
    ParentCreateExhibitionComponent,
    PreviewWorkofartComponent,
    ProfileViewComponent,
    PreviewExhibitionComponent,
    SearchResultsComponent,
    EditExhibitionComponent,
    SetExhibitionComponent,
    EditProfileComponent,
    EExhibitionsVrComponent,
    ConfirmMessageComponent,
    ConfirmExhibitionComponent,
    CreateExhibition6Component,
    PreviewFavouritesProfileComponent,
    DeleteWorkComponent,
    DeleteExhibitionComponent,
    PreviewCuratorComponent,
    EditWorkCuratorComponent,
    DeleteProfileUserComponent,
    DeleteProfileCuratorComponent,
    PreviewFullImageComponent,
    EditExhibitionCuratorComponent,
    ForgotPassword2Component,
    EExhibitionsVr2Component,
    EExhibitionsVrCustomComponent,
    DataGridComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxStarRatingModule,
    NgxSpinnerModule,
    AgGridModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      progressBar: false
    }),
    MatIconModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'el',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
    }
  }),
  ],
  providers: [
    AuthService,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
